import { Formik } from 'formik';
import * as Yup from 'yup';
import { ParticipantSubmit } from '../../models/Participant/Types';
import { QmDocumentDocx } from '../../models/QmDocument/Types';
import { CreateParticipantForm } from '../CreateParticipantForm/CreateParticipantForm';
import { ObjectShape } from 'yup';
import { useMeasures } from '../../models/Measure/Hooks';
import { useMemo } from 'react';
import { useNeedCommunities } from '../../models/NeedCommunity/Hooks';

interface CreateParticipantProps {
    onClose: () => void;
    onSetDocuments: (arg: QmDocumentDocx[]) => void;

    // If this is given, the participant will be created in the need community having the specified id
    needCommunityId?: number;
}

export const CreateParticipant = (props: CreateParticipantProps) => {
    const currentMeasure = useMeasures((x) => x.selectedMeasure);
    const needCommunities = useNeedCommunities((x) => x.needCommunities);
    const needCommunity = useMemo(() => {
        return needCommunities?.find((nc) => nc.id === props.needCommunityId);
    }, [needCommunities, props.needCommunityId]);

    const initialFormValues: ParticipantSubmit = {
        birthday: new Date(1980),
        birthplace: '',
        city: needCommunity?.city || '',
        comment: '',
        name: '',
        legalSphere: undefined,
        contact: '',
        stemGroup: '',
        supporter: '',
        clientNumber: '',
        employee: '',
        email: '',
        firstName: '',
        gender: 'diverse',
        highestGraduation: 'left_school_without_graduation_diploma',
        highestProfessionalGraduation: 'no_high_graduation',
        entranceDate: currentMeasure?.startAt ? new Date(currentMeasure?.startAt) : new Date(),
        lastName: '',
        mobilePhone: '',
        nationality: '',
        participantClientId: 0,
        phone: '',
        plannedLeaveDate: new Date(),
        streetAndNumber: needCommunity?.streetAndNumber || '',
        zipCode: needCommunity?.zipCode || '',
        clientLegalSphere: undefined,
        clientCustomerId: '',
        presenceType: '',
        highestGraduationOther: '',
        highestProfessionalGraduationOther: '',
        communityOfNeedsNo: needCommunity?.identifier
    };

    const validationSchema = (object: ParticipantSubmit) => {
        const returnObject: ObjectShape = {};
        Object.keys(object).forEach((key) => {
            // No validations for this fields
            if (['streetAndNumber', 'zipCode', 'city'].includes(key)) {
                return;
            }

            if (key === 'mobilePhone' || key === 'phone') {
                returnObject[key] = Yup.string()
                    .required('Dieses Feld muss ausgefüllt sein.')
                    .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein');
            } else {
                returnObject[key] = Yup.string().required('Dieses Feld muss ausgefüllt sein.');
            }
        });
        return returnObject;
    };

    const phoneRegx = /[0-9]/;
    const CreateParticipantValidationScheme = Yup.object().shape(validationSchema(initialFormValues)).defined();

    return (
        <Formik
            initialValues={initialFormValues}
            // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
            onSubmit={() => console.log('Form submit.')}
            validateOnMount={false}
            enableReinitialize={false}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={CreateParticipantValidationScheme}
        >
            {() => (
                <CreateParticipantForm
                    onSetDocuments={props.onSetDocuments}
                    onCloseSidebar={props.onClose}
                    needCommunity={needCommunity}
                />
            )}
        </Formik>
    );
};
