import React, { MouseEvent, ReactNode } from 'react';
import './Button.css';

/**
 * Props for the Button component.
 */
export interface ButtonProps {
    // button style type. Defaults to primary if not given
    type?: 'primary' | 'secondary' | 'danger';

    // button size, defaults to medium if not given
    size?: 'tiny' | 'small' | 'medium' | 'large';

    // button style, outline, link or filled. Defaults to filled if not given.
    buttonStyle?: 'filled' | 'outline' | 'link';

    // Indicates whether the button is disabled
    disabled?: boolean;

    // left icon in button
    firstIcon?: ReactNode;

    // right icon in button
    secondIcon?: ReactNode;

    // The content of the button
    text?: string;

    // Optional class name of the button
    className?: string;

    // Called if the user clicks the button, optional because the button can be used inside some a tag
    onClick?: () => void;
}

/**
 * Shows a button.
 *
 * @param props
 * @constructor
 */
export const Button = (props: ButtonProps) => {
    /**
     * Called if the user clicks the button.
     * Stops event propagation and bubbling and calls the event listener givyn be the props.
     *
     * @param event
     */
    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        if (!props.onClick) return;

        event.stopPropagation();
        event.preventDefault();
        props.onClick();
    };

    return (
        <div className={`button-container ${props.className ? props.className : ''}`}>
            <div
                onClick={onClick}
                className={`button ${props.type || 'primary'} ${props.buttonStyle || 'filled'} ${
                    props.size || 'medium'
                } ${props.disabled ? 'disabled' : ''} ${props.firstIcon ? 'icon' : ''} ${
                    props.secondIcon ? 'second-icon' : ''
                }`}
            >
                {props.firstIcon}
                {props.text}
                {props.secondIcon}
            </div>
        </div>
    );
};
