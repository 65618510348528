import { Formik } from 'formik';
import { useAppDispatch } from '../../hooks';
import { Measure } from '../../models/Measure/Types';
import { ParticipantDeactivationSubmit, ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Modal } from '../Modal/Modal';
import { ParticipantArchiveForm } from '../ParticipantArchiveForm/ParticipantArchiveForm';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { NeedCommunity } from '../../models/NeedCommunity/Types';
import { useParticipants } from '../../models/Participant/Hooks';
import { useMeasures } from '../../models/Measure/Hooks';
import { useNeedCommunitiesApi } from '../../api/useNeedCommunitiesApi';
import { needCommunityClosed } from '../../models/NeedCommunity/Slice';
import { forceParticipantsReload, participantDeactivated } from '../../models/Participant/Slice';

interface ParticipantArchiveModalProps {
    show: boolean;
    onClose: () => void;

    // If this is given, the archive request will be called on the need community instead of the current selected participant
    needCommunity?: NeedCommunity;
}

export const ParticipantArchiveModal = (props: ParticipantArchiveModalProps) => {
    const participant: ParticipantWithPresence | undefined = useParticipants((x) => x.selectedParticipant);
    const measure: Measure | undefined = useMeasures((x) => x.selectedMeasure);
    const { apiArchiveParticipantForMeasure } = useMeasuresApi();
    const { apiCloseNeedCommunity } = useNeedCommunitiesApi();
    const dispatch = useAppDispatch();

    /**
     * Called if the user submits the form.
     * Archives the participant, if no needCommunity is given via the props.
     * Closes the needCommunity, if the needCommunity is given by the props.
     */
    const onSubmit = async (values: ParticipantDeactivationSubmit) => {
        try {
            if (props.needCommunity) {
                await apiCloseNeedCommunity(props.needCommunity, values);
                dispatch(needCommunityClosed(props.needCommunity));
                dispatch(forceParticipantsReload());
                showSuccessMessage('Bedarfsgemeinschaft wurde erfolgreich geschlossen.');
            } else if (participant && participant.id && measure?.id && values) {
                await apiArchiveParticipantForMeasure(measure.id, participant.id, values);
                dispatch(forceParticipantsReload());

                dispatch(participantDeactivated({ participant, data: values }));
                showSuccessMessage('Teilnehmer wurde in Maßnahme archiviert');
            }

            props.onClose();
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const initialArchiveFormValues: ParticipantDeactivationSubmit = {
        date: new Date(),
        explanation: '',
        jobCompany: '',
        jobStartedAt: undefined,
        jobType: '',
        measureGoalAchieved: false,
        notes: '',
        reason: undefined,
        summary: ''
    };

    return (
        <Formik initialValues={initialArchiveFormValues} onSubmit={onSubmit} enableReinitialize>
            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                <Modal
                    show={props.show}
                    header={
                        props.needCommunity ? 'Bedarfsgemeinschaft schließen' : 'Teilnehmer in Maßnahme archivieren'
                    }
                    buttons={{
                        primary: {
                            text: props.needCommunity ? 'Bedarfsgemeinschaft schließen' : 'Teilnehmer archivieren',
                            onClick: handleSubmit
                        },
                        secondary: {
                            text: 'Abbrechen',
                            onClick: props.onClose
                        }
                    }}
                >
                    <ParticipantArchiveForm
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                </Modal>
            )}
        </Formik>
    );
};
