import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParticipantState } from './State';
import {
    Participant,
    ParticipantWithPresence,
    ParticipantMeasure,
    ParticipantClient,
    ParticipantDeactivationSubmit
} from './Types';
import { Presence } from '../Presence/Types';

/**
 * Slice state for redux work space
 */
export const ParticipantSlice = createSlice({
    name: 'participant',
    initialState: {
        selectedParticipant: undefined
    } as ParticipantState,
    reducers: {
        /**
         * Sets the given user to the state.
         *
         * @param state
         * @param action
         */
        setSelectedParticipant: (state, action: PayloadAction<ParticipantWithPresence | undefined>) => {
            state.selectedParticipant = action.payload;
        },

        /**
         * Sets the active participants of the current selected measure to the state.
         * @param state
         * @param action
         */
        setActiveParticipants: (state, action: PayloadAction<Participant[] | null>) => {
            state.activeParticipants = action.payload;
        },

        /**
         * Sets the participantsWithPresence of the current selected measure to the state.
         * @param state
         * @param action
         */
        setParticipantsWithPresences: (state, action: PayloadAction<ParticipantWithPresence[] | null>) => {
            state.participantsWithPresences = action.payload;
        },

        /**
         * Sets the inactive participants of the current selected measure to the state.
         * @param state
         * @param action
         */
        setInactiveParticipants: (state, action: PayloadAction<Participant[] | null>) => {
            state.inactiveParticipants = action.payload;
        },

        /**
         * Performs actions on the state that are needed after the creation of an participant.
         * Creates the participant in the lists.
         *
         * @param state
         * @param action
         */
        participantCreated: (state, action: PayloadAction<ParticipantWithPresence>) => {
            state.activeParticipants = [...(state.activeParticipants || []), action.payload];
            state.participantsWithPresences = [...(state.participantsWithPresences || []), action.payload];
        },

        /**
         * Performs actions on the state that are needed after the update of an participant.
         * Updates the participant in the lists and if he is selected also in the selected participant.
         *
         * @param state
         * @param action
         */
        participantUpdated: (state, action: PayloadAction<Participant>) => {
            if (state.selectedParticipant?.id === action.payload.id) {
                state.selectedParticipant = { ...state.selectedParticipant, ...action.payload };
            }

            state.activeParticipants = (state.activeParticipants || []).map((participant) => {
                if (participant.id === action.payload.id) {
                    return action.payload;
                } else {
                    return participant;
                }
            });

            state.inactiveParticipants = (state.inactiveParticipants || []).map((participant) => {
                if (participant.id === action.payload.id) {
                    return action.payload;
                } else {
                    return participant;
                }
            });

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.id) {
                    return { ...participant, ...action.payload };
                } else {
                    return participant;
                }
            });
        },

        /**
         * Called if the participant was removed from the current selected measure.
         * Performs necessary steps on the state to remove the participant from all sources.
         *
         * @param state
         * @param action
         */
        participantRemovedFromMeasure: (state, action: PayloadAction<Participant>) => {
            if (state.selectedParticipant?.id === action.payload.id) {
                state.selectedParticipant = undefined;
            }

            state.activeParticipants = (state.activeParticipants || []).filter((participant) => {
                return participant.id !== action.payload.id;
            });

            state.inactiveParticipants = (state.inactiveParticipants || []).filter((participant) => {
                return participant.id !== action.payload.id;
            });

            state.participantsWithPresences = (state.participantsWithPresences || []).filter((participant) => {
                return participant.id !== action.payload.id;
            });
        },

        /**
         * Called if the participant was deactivated.
         * Performs the necessary steps on the state after reactivation.
         * Removes the participant from the inactive list and adds it to the active list.
         *
         * @param state
         * @param action
         */
        participantDeactivated: (
            state,
            action: PayloadAction<{ participant: ParticipantWithPresence; data: ParticipantDeactivationSubmit }>
        ) => {
            if (state.selectedParticipant?.id === action.payload.participant.id) {
                state.selectedParticipant.measuresParticipant = {
                    ...state.selectedParticipant.measuresParticipant,
                    ...action.payload.data,
                    inactive: true,
                    inactiveReason: action.payload.data.reason // this is the only variable name that differs...
                };
            }

            // In these lists the data is not important because it has no measuresParticipant entry
            state.activeParticipants = (state.activeParticipants || []).filter((participant) => {
                return participant.id !== action.payload.participant.id;
            });
            state.inactiveParticipants = [...(state.inactiveParticipants || []), action.payload.participant];

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.participant.id) {
                    return {
                        ...participant,
                        ...action.payload.data,
                        inactive: true,
                        inactiveReason: action.payload.data.reason // this is the only variable name that differs...
                    };
                }

                return participant;
            });
        },

        /**
         * Called if the participant was reactivated.
         * Performs the necessary steps on the state after reactivation.
         * Removes the participant from the inactive list and adds it to the active list.
         *
         * @param state
         * @param action
         */
        participantReactivated: (state, action: PayloadAction<Participant>) => {
            if (state.selectedParticipant?.id === action.payload.id) {
                state.selectedParticipant.measuresParticipant.inactive = false;
                state.selectedParticipant.measuresParticipant.inactiveReason = undefined;
                state.selectedParticipant.measuresParticipant.inactiveDate = undefined;
                state.selectedParticipant.measuresParticipant.measureGoalAchieved = undefined;
                state.selectedParticipant.measuresParticipant.entranceDate = new Date();
            }

            // In these lists the data is not important because it has no measuresParticipant entry
            state.inactiveParticipants = (state.inactiveParticipants || []).filter((participant) => {
                return participant.id !== action.payload.id;
            });
            state.activeParticipants = [...(state.activeParticipants || []), action.payload];

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.id) {
                    const newParticipant = { ...participant };
                    newParticipant.measuresParticipant.inactive = false;
                    newParticipant.measuresParticipant.inactiveReason = undefined;
                    newParticipant.measuresParticipant.inactiveDate = undefined;
                    newParticipant.measuresParticipant.measureGoalAchieved = undefined;
                    newParticipant.measuresParticipant.entranceDate = new Date();

                    return newParticipant;
                }

                return participant;
            });
        },

        /**
         * Called if a presence of a participant was created.
         * Performs the necessary updates on the state.
         *
         * Note that we do not check the correctness of the measure here. It is expected that
         * the participant objects only hold presences of the current selected measure and the
         * given presence is also from the current selected measure.
         *
         * @param state
         * @param action
         */
        presenceCreated: (state, action: PayloadAction<Presence>) => {
            if (state.selectedParticipant?.id === action.payload.participantId) {
                state.selectedParticipant.presences = [...state.selectedParticipant.presences, action.payload];
            }

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.participantId) {
                    participant.presences.push(action.payload);
                }

                return participant;
            });
        },

        /**
         * Called if a presence of a participant was updated.
         * Performs the necessary updates on the state.
         *
         * Note that we do not check the correctness of the measure here. It is expected that
         * the participant objects only hold presences of the current selected measure and the
         * given presence is also from the current selected measure.
         *
         * @param state
         * @param action
         */
        presenceUpdated: (state, action: PayloadAction<Presence>) => {
            if (state.selectedParticipant?.id === action.payload.participantId) {
                state.selectedParticipant.presences = state.selectedParticipant.presences.map((presence) => {
                    if (presence.id === action.payload.id) {
                        return action.payload;
                    }

                    return presence;
                });
            }

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.participantId) {
                    participant.presences = participant.presences.map((presence) => {
                        if (presence.id === action.payload.id) {
                            return action.payload;
                        }

                        return presence;
                    });
                }

                return participant;
            });
        },

        /**
         * Called after the measuresParticipant object of an participant was updated.
         * Performs the necessary updates on the state.
         *
         * Note that we do not check the correctness of the measure here. It is expected that
         * the participant objects only hold presences of the current selected measure and the
         * given presence is also from the current selected measure.
         *
         * @param state
         * @param action
         */
        measuresParticipantUpdated: (state, action: PayloadAction<ParticipantMeasure>) => {
            if (state.selectedParticipant?.id === action.payload.participantId) {
                state.selectedParticipant.measuresParticipant = action.payload;
            }

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.participantId) {
                    participant.measuresParticipant = action.payload;
                }

                return participant;
            });
        },

        /**
         * Called if the participantClient of an participant was updated.
         * Performs all necessary actions on the state.
         *
         * @param state
         * @param action
         */
        participantClientUpdated: (
            state,
            action: PayloadAction<{ participant: Participant; participantClient: ParticipantClient }>
        ) => {
            if (state.selectedParticipant?.id === action.payload.participant.id) {
                state.selectedParticipant.participantClientId = action.payload.participantClient.id;
                state.selectedParticipant.participantClient = action.payload.participantClient;
            }

            state.activeParticipants = (state.activeParticipants || []).map((participant) => {
                if (participant.id === action.payload.participant.id) {
                    participant.participantClientId = action.payload.participantClient.id;
                    participant.participantClient = action.payload.participantClient;
                }

                return participant;
            });

            state.inactiveParticipants = (state.inactiveParticipants || []).map((participant) => {
                if (participant.id === action.payload.participant.id) {
                    participant.participantClientId = action.payload.participantClient.id;
                    participant.participantClient = action.payload.participantClient;
                }

                return participant;
            });

            state.participantsWithPresences = (state.participantsWithPresences || []).map((participant) => {
                if (participant.id === action.payload.participant.id) {
                    participant.participantClientId = action.payload.participantClient.id;
                    participant.participantClient = action.payload.participantClient;
                }

                return participant;
            });
        },

        /**
         * Sets the force reload value that forces the state manager to refetch all data from tha backend.
         * The state manager is expected to reset this value.
         *
         * @param state
         */
        forceParticipantsReload: (state) => {
            state.forceReload = true;
        },

        /**
         * Unsets the value to force a reload from the backend.
         * Is expected to be called by the state manager after the reload was completed.
         *
         * @param state
         */
        unsetForceParticipantsReload: (state) => {
            state.forceReload = undefined;
        }
    }
});

export const {
    setSelectedParticipant,
    setActiveParticipants,
    setInactiveParticipants,
    setParticipantsWithPresences,
    participantCreated,
    participantUpdated,
    participantRemovedFromMeasure,
    participantDeactivated,
    participantReactivated,
    presenceCreated,
    presenceUpdated,
    measuresParticipantUpdated,
    participantClientUpdated,
    forceParticipantsReload,
    unsetForceParticipantsReload
} = ParticipantSlice.actions;
