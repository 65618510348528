import { useCallback, useState } from 'react';
import { CreateParticipant } from '../../components/CreateParticipant/CreateParticipant';
import { ParticipantDetails } from '../../components/ParticipantDetails/ParticipantDetails';
import { ParticipantsList } from '../../components/ParticipantsList/ParticipantsList';
import { PrintQmDocumentModal } from '../../components/PrintQmDocumentModal/PrintQmDocumentModal';
import { StandardView } from '../../components/StandardView/StandardView';
import { useAppDispatch } from '../../hooks';
import { Participant } from '../../models/Participant/Types';
import { QmDocumentDocx } from '../../models/QmDocument/Types';
import './UserMeasureParticipantManagement.css';
import { setSelectedParticipant } from '../../models/Participant/Slice';
import { useParticipants } from '../../models/Participant/Hooks';
import { useMeasures } from '../../models/Measure/Hooks';
import { useNeedCommunities } from '../../models/NeedCommunity/Hooks';

export const UserMeasureParticipantManagement = () => {
    const selectedMeasure = useMeasures((x) => x.selectedMeasure);
    const needCommunities = useNeedCommunities((x) => x.needCommunities);
    const activeParticipants = useParticipants((x) => x.activeParticipants);
    const inactiveParticipants = useParticipants((x) => x.inactiveParticipants);
    const selectedParticipant = useParticipants((x) => x.selectedParticipant);
    const participantsWithPresences = useParticipants((x) => x.participantsWithPresences);

    const [showCreateParticipant, setShowCreateParticipant] = useState<{ needCommunityId?: number } | false>(false);
    const [documentModal, setDocumentModal] = useState<boolean>(false);
    const [documents, setDocuments] = useState<QmDocumentDocx[]>();

    const dispatch = useAppDispatch();

    const onOpenDocumentModal = () => {
        setDocumentModal(!documentModal);
    };

    const onSetDocuments = (documents: QmDocumentDocx[] | undefined) => {
        setDocuments(documents);
    };

    /**
     * Shows the create participant view.
     * If a needCommunityId is passed, the needCommunity to be created with will be saved, hence the
     * participant will be added to the need community after creation.
     */
    const onCreateNewParticipant = (needCommunityId?: number) => {
        setShowCreateParticipant({ needCommunityId });
    };

    /**
     * Closes the participant creation modal.
     */
    const onCloseCreateParticipant = () => {
        setShowCreateParticipant(false);
    };

    /**
     * Called if some participant gets selected from the list.
     * Dispatches the selected participant to the global state.
     *
     * @param participant
     */
    const onSelectParticipant = useCallback(
        (participant: Participant) => {
            const participantWithPresences = participantsWithPresences?.find(
                (participantWithPresence) => participantWithPresence.id === participant?.id
            );

            dispatch(setSelectedParticipant(participantWithPresences));
        },
        [dispatch, participantsWithPresences]
    );

    return (
        <StandardView>
            <PrintQmDocumentModal
                show={!!documents}
                onSetDocuments={onSetDocuments}
                close={onOpenDocumentModal}
                documents={documents}
            />
            {showCreateParticipant ? (
                <CreateParticipant
                    onSetDocuments={onSetDocuments}
                    onClose={onCloseCreateParticipant}
                    needCommunityId={showCreateParticipant.needCommunityId}
                />
            ) : (
                <></>
            )}
            <>
                {' '}
                <StandardView.Left>
                    <ParticipantsList
                        onSelectParticipant={onSelectParticipant}
                        onCreateNewParticipant={onCreateNewParticipant}
                        selectedParticipant={selectedParticipant}
                        activeParticipants={activeParticipants || []}
                        inactiveParticipants={inactiveParticipants || []}
                        needCommunities={selectedMeasure?.hasNeedCommunities ? needCommunities || [] : undefined}
                    />{' '}
                </StandardView.Left>
                <StandardView.Right>
                    {selectedParticipant && activeParticipants && (
                        <ParticipantDetails participant={selectedParticipant} />
                    )}
                </StandardView.Right>
            </>
        </StandardView>
    );
};
