import {
    ParticipantGraduation,
    ParticipantLegalSphere,
    ParticipantProfessionalTraining,
    ParticipantWithPresence
} from '../../models/Participant/Types';
import { Label } from '../Label/Label';
import { List } from '../List/List';
import { FormikErrors } from 'formik';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { mapGender } from '../../models/User/User';
import { formatDate } from '../../models/Date/Date';
import {
    mapGraduation,
    mapProfessionalTraining,
    participantGraduation,
    participantProfessionalTraining
} from '../../models/Participant/Participant';
import { mapLegalSphere } from '../../models/Measure/Measure';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import React from 'react';
import { DropDown } from '../DropDown/DropDown';
import { countries } from '../../models/Countries/Countries';
import { mapPresenceType } from '../../models/ParticipantMeasure/ParticipantMeasure';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useParticipantClientsApi } from '../../api/useParticipantClientsApi';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useAppDispatch } from '../../hooks';
import {
    measuresParticipantUpdated,
    participantClientUpdated,
    participantUpdated
} from '../../models/Participant/Slice';
import { UserGender } from '../../models/User/Types';

interface ParticipantMuchDetailsCardFormProps {
    participant: ParticipantWithPresence;
    values: ParticipantWithPresence;
    errors: FormikErrors<ParticipantWithPresence>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
}

export const ParticipantMuchDetailsCardForm = (props: ParticipantMuchDetailsCardFormProps) => {
    const { apiUpdateParticipant } = useParticipantsApi();
    const { apiUpdateParticipantClient } = useParticipantClientsApi();
    const { apiUpdateMeasureParticipant } = useMeasuresApi();
    const dispatch = useAppDispatch();

    /**
     * Update the participant date in an onblur event
     */
    const onBlur = async () => {
        try {
            if (JSON.stringify(props.participant) !== JSON.stringify(props.values)) {
                const updatedParticipant = await apiUpdateParticipant(props.participant.id, props.values);

                dispatch(participantUpdated(updatedParticipant));

                if (
                    JSON.stringify(props.participant.participantClient) !==
                    JSON.stringify(props.values.participantClient)
                ) {
                    const updatedParticipantClient = await apiUpdateParticipantClient(
                        props.participant.participantClientId,
                        props.values.participantClient
                    );

                    dispatch(
                        participantClientUpdated({
                            participant: props.participant,
                            participantClient: updatedParticipantClient
                        })
                    );
                }

                showSuccessMessage('Teilnehmer aktualisiert');
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Trigger the onblur event if the user hits the enter key in a text field
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    /**
     * Update the birthday manually
     * @param value
     */
    const onChangeBirthday = async (value: Date) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ birthday: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('birthday', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the entrance date
     * @param value
     */
    const onChangeEntranceDate = async (value: Date) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ entranceDate: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                const updatedMeasuresParticipant = await apiUpdateMeasureParticipant(
                    props.participant.measuresParticipant.id,
                    {
                        ...props.participant.measuresParticipant,
                        ...{ entranceDate: value.toString() }
                    }
                );

                dispatch(measuresParticipantUpdated(updatedMeasuresParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('entranceDate', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the planned leave date
     * @param value
     */
    const onChangePlannedLeaveDate = async (value: Date) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ plannedLeaveDate: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('plannedLeaveDate', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the country
     * @param value
     */
    const onChangeCountry = async (value: string) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ nationality: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('nationality', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the professional training value
     * @param value
     */
    const onChangeProfessionalTraining = async (value: ParticipantProfessionalTraining) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ highestProfessionalGraduation: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('highestProfessionalGraduation', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the graduation
     * @param value
     */
    const onChangeGraduation = async (value: ParticipantGraduation) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.values,
                    ...{ highestGraduation: value }
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('highestGraduation', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the legal sphere
     * @param value
     */
    const onChangeLegalSphere = async (value: ParticipantLegalSphere) => {
        try {
            if (props.participant) {
                const updatedParticipantClient = await apiUpdateParticipantClient(
                    props.participant.participantClientId,
                    {
                        legalSphere: value
                    }
                );

                dispatch(
                    participantClientUpdated({
                        participant: props.participant,
                        participantClient: updatedParticipantClient
                    })
                );

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('participantClient.legalSphere', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the gender
     * @param value
     */
    const onChangeGender = async (value: UserGender) => {
        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant.id, {
                    gender: value
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('gender', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Update the presence type
     * @param value
     */
    const onChangePresenceType = async (value: 'full_time' | 'part_time') => {
        try {
            if (props.participant) {
                const updatedMeasuresParticipant = await apiUpdateMeasureParticipant(
                    props.participant.measuresParticipant.id,
                    {
                        ...props.values.measuresParticipant,
                        ...{ presenceType: value }
                    }
                );

                dispatch(measuresParticipantUpdated(updatedMeasuresParticipant));

                showSuccessMessage('Teilnehmer aktualisiert');
            }

            props.setFieldValue('measuresParticipant.presenceType', value);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <>
            <div className={'participant-much-details-card-list'} key={props.participant.id}>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        PERSONALIEN
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Name',
                                value: props.values.lastName,
                                name: 'lastName',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Vorname',
                                value: props.values.firstName,
                                name: 'firstName',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Geschlecht',
                                value: mapGender(props.values.gender),
                                input: (
                                    <DropDown
                                        title={<div>{mapGender(props.values.gender)}</div>}
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        <div onClick={() => onChangeGender('male')}>männlich</div>
                                        <div onClick={() => onChangeGender('female')}>weiblich</div>
                                        <div onClick={() => onChangeGender('diverse')}>divers</div>
                                    </DropDown>
                                )
                            },
                            {
                                label: 'Geburtsdatum',
                                value: formatDate(new Date(props.values.birthday)),
                                input: (
                                    <Datepicker
                                        startYear={1950}
                                        value={new Date(props.values.birthday)}
                                        onChange={onChangeBirthday}
                                        returnInputFieldDate={onChangeBirthday}
                                    >
                                        {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                            <div className="details-form-list-calendar" onClick={openDatepicker}>
                                                <input
                                                    className={'editable-item'}
                                                    value={formatDate(new Date(props.values.birthday))}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            </div>
                                        )}
                                    </Datepicker>
                                )
                            },
                            {
                                label: 'Geburtsort',
                                value: props.values.birthplace,
                                name: 'birthplace',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Nationalität',
                                value: props.values.nationality,
                                input: (
                                    <DropDown
                                        title={<div>{props.values.nationality}</div>}
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        {countries.map((country, index) => {
                                            return (
                                                <div key={index} onClick={() => onChangeCountry(country)}>
                                                    {country}
                                                </div>
                                            );
                                        })}
                                    </DropDown>
                                )
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        KONTAKTDATEN
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Straße & Hausnummer',
                                value: props.values.streetAndNumber,
                                name: 'streetAndNumber',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'PLZ',
                                value: props.values.zipCode,
                                name: 'zipCode',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Ort',
                                value: props.values.city,
                                name: 'city',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Telefon',
                                value: props.values.phone,
                                name: 'phone',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'mobil',
                                value: props.values.mobilePhone,
                                name: 'mobilePhone',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'E-Mail',
                                value: props.values.email,
                                name: 'email',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        DATEN ZUR TEILNAHME
                    </Label>
                    <List
                        options={[
                            {
                                label: 'höchster Schulabschluss',
                                value: mapGraduation(props.values.highestGraduation),
                                input: (
                                    <DropDown
                                        title={<div>{mapGraduation(props.values.highestGraduation)}</div>}
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        {participantGraduation.map((graduation, index) => {
                                            return (
                                                <div key={index} onClick={() => onChangeGraduation(graduation)}>
                                                    {mapGraduation(graduation)}
                                                </div>
                                            );
                                        })}
                                    </DropDown>
                                )
                            },
                            {
                                label: 'höchster Berufsabschluss',
                                value: mapProfessionalTraining(props.values.highestProfessionalGraduation),
                                input: (
                                    <DropDown
                                        title={
                                            <div>
                                                {mapProfessionalTraining(props.values.highestProfessionalGraduation)}
                                            </div>
                                        }
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        {participantProfessionalTraining.map((training, index) => {
                                            return (
                                                <div key={index} onClick={() => onChangeProfessionalTraining(training)}>
                                                    {mapProfessionalTraining(training)}
                                                </div>
                                            );
                                        })}
                                    </DropDown>
                                )
                            },
                            {
                                label: 'Stammgruppe',
                                value: props.values.stemGroup,
                                name: 'stemGroup',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Eintritt',
                                value: formatDate(new Date(props.participant.measuresParticipant.entranceDate)),
                                input: (
                                    <Datepicker
                                        value={new Date(props.participant.measuresParticipant.entranceDate)}
                                        onChange={onChangeEntranceDate}
                                        returnInputFieldDate={onChangeEntranceDate}
                                    >
                                        {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                            <div className="details-form-list-calendar" onClick={openDatepicker}>
                                                <input
                                                    className={'editable-item'}
                                                    value={formatDate(
                                                        new Date(props.participant.measuresParticipant.entranceDate)
                                                    )}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            </div>
                                        )}
                                    </Datepicker>
                                )
                            },
                            {
                                label: 'geplanter Austritt',
                                value: formatDate(new Date(props.values.plannedLeaveDate)),
                                input: (
                                    <Datepicker
                                        value={new Date(props.values.plannedLeaveDate)}
                                        onChange={onChangePlannedLeaveDate}
                                        returnInputFieldDate={onChangePlannedLeaveDate}
                                    >
                                        {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                            <div className="details-form-list-calendar" onClick={openDatepicker}>
                                                <input
                                                    className={'editable-item'}
                                                    value={formatDate(new Date(props.values.plannedLeaveDate))}
                                                    onChange={onChangeDateInForm}
                                                    onPaste={onPasteDate}
                                                    onKeyDown={onPressEnterSubmit}
                                                />
                                            </div>
                                        )}
                                    </Datepicker>
                                )
                            },
                            {
                                label: 'Anwesenheit',
                                value: mapPresenceType(props.values.measuresParticipant.presenceType),
                                input: (
                                    <DropDown
                                        title={
                                            <div>{mapPresenceType(props.values.measuresParticipant.presenceType)}</div>
                                        }
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        <div onClick={() => onChangePresenceType('full_time')}>Vollzeit</div>
                                        <div onClick={() => onChangePresenceType('part_time')}>Teilzeit</div>
                                    </DropDown>
                                )
                            }
                        ]}
                    />
                </div>
                <div>
                    <Label className="user-details-general-title" size={2}>
                        DATEN ZUM AUFTRAGGEBER
                    </Label>
                    <List
                        options={[
                            {
                                label: 'Kundennr.',
                                value: props.values.clientCustomerId,
                                name: 'clientCustomerId',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Auftraggeber.',
                                value: props.values.participantClient.name,
                                name: 'participantClient.name',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            },
                            {
                                label: 'Rechtskreis',
                                value: mapLegalSphere(props.values.participantClient.legalSphere),
                                input: (
                                    <DropDown
                                        title={<div>{mapLegalSphere(props.values.participantClient.legalSphere)}</div>}
                                        titleClassName={'dropdown-list-title'}
                                        className={'dropdown-list'}
                                    >
                                        <div onClick={() => onChangeLegalSphere('sgb_2')}>SGB II</div>
                                        <div onClick={() => onChangeLegalSphere('sgb_3')}>SGB III</div>
                                    </DropDown>
                                )
                            },
                            {
                                label: 'Mitarbeiter/in Auftraggeber',
                                value: props.values.participantClient.contact,
                                name: 'participantClient.contact',
                                onChange: props.handleChange,
                                onBlur: onBlur,
                                onKeyDown: onKeyEnter
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    );
};
