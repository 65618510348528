import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NeedCommunitiesState } from './State';
import { NeedCommunity } from './Types';
import { Participant } from '../Participant/Types';

/**
 * Slice state for redux work space
 */
export const NeedCommunitiesSlice = createSlice({
    name: 'needCommunities',
    initialState: {
        needCommunities: null,
        selectedNeedCommunity: null
    } as NeedCommunitiesState,
    reducers: {
        /**
         * Sets all need comminities of the current selected measure to the state.
         *
         * @param state
         * @param action
         */
        setNeedCommunities: (state, action: PayloadAction<NeedCommunity[] | null>) => {
            state.needCommunities = action.payload;
        },

        /**
         * Sets the selected need community of the current selected measure in the state.
         *
         * @param state
         * @param action
         */
        setSelectedNeedCommunity: (state, action: PayloadAction<NeedCommunity | null>) => {
            state.selectedNeedCommunity = action.payload;
        },

        /**
         * Called if the need community was created.
         * Performs actions on the state that are needed after creation.
         * Adds the need community to the list.
         * Sets the new need community as the sledected one to be able to edit it.
         *
         * @param state
         * @param action
         */
        needCommunityCreated: (state, action: PayloadAction<NeedCommunity>) => {
            state.needCommunities = [...(state.needCommunities || []), action.payload];
            state.selectedNeedCommunity = action.payload;
        },

        /**
         * Called if the need community was updated.
         * Performs actions on the state that are needed after update.
         * Update the need community if it is the selected one.
         * Updates the need community in the list.
         *
         * @param state
         * @param action
         */
        needCommunityUpdated: (state, action: PayloadAction<NeedCommunity>) => {
            if (state.selectedNeedCommunity?.id === action.payload.id) {
                // We need to destructure here because of the participants array, that would possibly removed
                state.selectedNeedCommunity = { ...state.selectedNeedCommunity, ...action.payload };

                // Update the address and comunity of needs no for all participants
                state.selectedNeedCommunity.participants = (state.selectedNeedCommunity.participants || []).map(
                    (participant) => {
                        return {
                            ...participant,
                            streetAndNumber: action.payload.streetAndNumber,
                            zipCode: action.payload.zipCode,
                            city: action.payload.city,
                            communityOfNeedsNo: action.payload.identifier
                        };
                    }
                );
            }

            state.needCommunities = (state.needCommunities || []).map((needCommunity) => {
                if (needCommunity.id === action.payload.id) {
                    // We need to destructure here because of the participants array, that would possibly removed
                    const newNeedCommunity = { ...needCommunity, ...action.payload };
                    newNeedCommunity.participants = (newNeedCommunity.participants || []).map((participant) => {
                        return {
                            ...participant,
                            streetAndNumber: action.payload.streetAndNumber,
                            zipCode: action.payload.zipCode,
                            city: action.payload.city,
                            communityOfNeedsNo: action.payload.identifier
                        };
                    });

                    return newNeedCommunity;
                } else {
                    return needCommunity;
                }
            });
        },

        /**
         * Called if the need community was deleted.
         * Performs actions on the state that are needed after deletion.
         * Deselects the need community if it is the selected one.
         * Removes the need community from the list.
         *
         * @param state
         * @param action
         */
        needCommunityDeleted: (state, action: PayloadAction<NeedCommunity>) => {
            if (state.selectedNeedCommunity?.id === action.payload.id) {
                state.selectedNeedCommunity = null;
            }

            state.needCommunities = (state.needCommunities || []).filter((needCommunity) => {
                return needCommunity.id !== action.payload.id;
            });
        },

        /**
         * Called if the need community was closed.
         * Performs actions on the state that are needed after closing.
         * Sets the closed flag to the list and the selected need community if it is selected.
         *
         * @param state
         * @param action
         */
        needCommunityClosed: (state, action: PayloadAction<NeedCommunity>) => {
            if (state.selectedNeedCommunity?.id === action.payload.id) {
                state.selectedNeedCommunity.closed = true;
            }

            state.needCommunities = (state.needCommunities || []).map((needCommunity) => {
                if (needCommunity.id === action.payload.id) {
                    needCommunity.closed = true;
                }

                return needCommunity;
            });
        },

        /**
         * Called if the participant was added to the need community.
         * Performs actions on the state that are needed after the participanzt was added.
         * Adds the participant to the selected need community, if it is currently selected.
         * Adds the participant to the need comminity in the list.
         *
         * @param state
         * @param action
         */
        needCommunityParticipantAdded: (
            state,
            action: PayloadAction<{ needCommunity: NeedCommunity; participant: Participant }>
        ) => {
            if (state.selectedNeedCommunity?.id === action.payload.needCommunity.id) {
                state.selectedNeedCommunity.participants = [
                    ...(state.selectedNeedCommunity.participants || []),
                    action.payload.participant
                ];
            }

            state.needCommunities?.map((needCommunity) => {
                if (needCommunity.id === action.payload.needCommunity.id) {
                    needCommunity.participants = [...(needCommunity.participants || []), action.payload.participant];

                    return needCommunity;
                } else {
                    return needCommunity;
                }
            });
        },

        /**
         * Called if the participant was removed from the need community.
         * Performs actions on the state that are needed after the participanzt was added.
         * Adds the participant to the selected need community, if it is currently selected.
         * Adds the participant to the need comminity in the list.
         *
         * @param state
         * @param action
         */
        needCommunityParticipantRemoved: (
            state,
            action: PayloadAction<{ needCommunity: NeedCommunity; participant: Participant }>
        ) => {
            if (state.selectedNeedCommunity?.id === action.payload.needCommunity.id) {
                state.selectedNeedCommunity.participants = state.selectedNeedCommunity.participants?.filter(
                    (participant) => {
                        return participant.id !== action.payload.participant.id;
                    }
                );
            }

            state.needCommunities?.map((needCommunity) => {
                if (needCommunity.id === action.payload.needCommunity.id) {
                    needCommunity.participants = needCommunity.participants?.filter((participant) => {
                        return participant.id !== action.payload.participant.id;
                    });

                    return needCommunity;
                } else {
                    return needCommunity;
                }
            });
        }
    }
});

export const {
    setNeedCommunities,
    setSelectedNeedCommunity,
    needCommunityCreated,
    needCommunityUpdated,
    needCommunityDeleted,
    needCommunityClosed,
    needCommunityParticipantAdded,
    needCommunityParticipantRemoved
} = NeedCommunitiesSlice.actions;
