import { useMeasures } from '../models/Measure/Hooks';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks';
import { setNeedCommunities, setSelectedNeedCommunity } from '../models/NeedCommunity/Slice';
import { useNeedCommunitiesApi } from '../api/useNeedCommunitiesApi';
import {
    setActiveParticipants,
    setInactiveParticipants,
    setParticipantsWithPresences,
    setSelectedParticipant,
    unsetForceParticipantsReload
} from '../models/Participant/Slice';
import { useMeasuresApi } from '../api/useMeasuresApi';
import { useParticipants } from '../models/Participant/Hooks';

/**
 * State manager that reacts on changes of the current selected measure.
 * Fetches related information of the measure.
 *
 * @constructor
 */
export const MeasureStateManager = () => {
    const selectedMeasure = useMeasures((x) => x.selectedMeasure);
    const [selectedMeasureId, setSelectedMeasureId] = useState<number | undefined>(undefined);
    const dispatch = useAppDispatch();
    const { apiGetNeedCommunities } = useNeedCommunitiesApi();
    const {
        apiGetActiveParticipantsInMeasure,
        apiGetInactiveParticipantsInMeasure,
        apiGetParticipantsWithPresencesInMeasure
    } = useMeasuresApi();
    const forceParticipantsReload = useParticipants((x) => x.forceReload);

    /**
     * Fetches all participants from the backend and saves it to the state.
     */
    const loadParticipants = useCallback(async () => {
        if (!selectedMeasureId) {
            dispatch(setSelectedParticipant(undefined));
            dispatch(setActiveParticipants(null));
            dispatch(setInactiveParticipants(null));
            dispatch(setParticipantsWithPresences(null));

            return;
        }

        const activeParticipants = await apiGetActiveParticipantsInMeasure(selectedMeasureId);
        dispatch(setActiveParticipants(activeParticipants));

        const inactiveParticipants = await apiGetInactiveParticipantsInMeasure(selectedMeasureId);
        dispatch(setInactiveParticipants(inactiveParticipants));

        const participantWithPresences = await apiGetParticipantsWithPresencesInMeasure(selectedMeasureId);
        dispatch(setParticipantsWithPresences(participantWithPresences));
    }, [
        selectedMeasureId,
        apiGetActiveParticipantsInMeasure,
        dispatch,
        apiGetInactiveParticipantsInMeasure,
        apiGetParticipantsWithPresencesInMeasure
    ]);

    /**
     * Called if the selercted measure was changed.
     * If the id differs from the current selectedMeasureId, it will be updated.
     * This makes it possible to call actions only if the measure was changed, and not on every uodate
     * of the measure itself.
     */
    useEffect(() => {
        if (selectedMeasureId !== selectedMeasure?.id) {
            setSelectedMeasureId(selectedMeasure?.id);
        }
    }, [selectedMeasure, selectedMeasureId]);

    /**
     * Called if the current selected measure changes.
     * Fetches the need communities of the measure.
     */
    useEffect(() => {
        if (!selectedMeasureId) {
            dispatch(setSelectedNeedCommunity(null));
            dispatch(setNeedCommunities(null));
        } else {
            (async () => {
                const needCommunities = await apiGetNeedCommunities(selectedMeasureId);
                dispatch(setNeedCommunities(needCommunities));
            })();
        }
    }, [apiGetNeedCommunities, dispatch, selectedMeasureId]);

    /**
     * Called if the current selected measure changes.
     * Fetches all active and inactive participants of the measure from the backend and sets it to the state.
     */
    useEffect(() => {
        (async () => {
            await loadParticipants();
        })();
    }, [loadParticipants]);

    /**
     * Called if the variable to force a reload for all participants was set.
     * Reloads all participants from the server and updates the state.
     */
    useEffect(() => {
        if (!forceParticipantsReload) return;

        (async () => {
            await loadParticipants();

            dispatch(unsetForceParticipantsReload());
        })();
    }, [dispatch, forceParticipantsReload, loadParticipants]);

    return null;
};
