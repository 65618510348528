import { ParticipantMeasure } from '../../models/Participant/Types';
import { List } from '../List/List';
import styles from './MeasuresParticipantFamilyCard.module.css';
import { Card } from '../Card/Card';
import React from 'react';

/**
 * Props for the MeasuresParticipantFamily component.
 */
interface MeasuresParticipantFamilyCardProps {
    // The record that parent and children should be shown
    measuresParticipant: ParticipantMeasure;
}

/**
 * Shows the parent and the children of the measuresParticipant object given by the props.
 *
 * @param props
 * @constructor
 */
export const MeasuresParticipantFamilyCard = (props: MeasuresParticipantFamilyCardProps) => {
    return (
        <Card className={styles.container}>
            <div className={`${styles.title} p2-medium`}>Vorherige- / Folgende Maßnahmen</div>

            {props.measuresParticipant.parent && (
                <>
                    <div>{props.measuresParticipant.parent.measure?.name}</div>
                    <List
                        readonly={true}
                        options={[
                            {
                                label: 'Eintritt',
                                value: `${new Date(props.measuresParticipant.parent.createdAt).toLocaleDateString()}`
                            },
                            {
                                label: 'Austritt',
                                value: `${new Date(props.measuresParticipant.parent.inactiveDate || Date.now()).toLocaleDateString()}`
                            }
                        ]}
                    />
                </>
            )}

            {(props.measuresParticipant.children?.length || 0) > 0 && (
                <List
                    readonly={true}
                    options={[
                        {
                            label: 'Folgende Maßnahmen',
                            value: props.measuresParticipant.children
                                ?.map((child) => {
                                    return `${child.measure?.name} (${new Date(child.createdAt).toLocaleDateString()})`;
                                })
                                .join(', ')
                        }
                    ]}
                />
            )}
        </Card>
    );
};
